import React from "react";

import Card from "src/components/atoms/Card/Card";
import ProgressIcon from "src/components/atoms/Icons/ProgressIcon";
import RangeMeter from "src/components/atoms/ProgressRangeMeter";

import RIGHTICON from "../../../../assets/icons/progress/view-more-icon.svg";

interface ProgressResultDetailsCardProps {
  progressTitle: string;
  progressValue: number | string;
  progressUnit: string;
  vitalBg: string;
  vitalTextColor: string;
  idealRange: string;
  loggedDate: string;
  status: string;
  handleOnclick?: () => void;
  activeRange?: number;
  startRange?: number;
  endRange?: number;
  onclickOfCard?: () => void;
}

const ProgressResultDetailsCard: React.FC<ProgressResultDetailsCardProps> = ({
  progressTitle,
  progressValue,
  progressUnit,
  vitalBg,
  vitalTextColor,
  idealRange,
  loggedDate,
  status,
  handleOnclick,
  startRange = 0,
  endRange = 0,
  activeRange = 0,
  onclickOfCard,
}) => {
  return (
    <div className="w-full h-auto">
      <Card className="!rounded-3xl flex flex-col gap-4 p-4">
        <div className="flex justify-between gap-4 items-center">
          <div className="font-semibold text-base text-text-primary w-full">{progressTitle}</div>
          <button onClick={handleOnclick}>
            <ProgressIcon image={RIGHTICON} className="w-6 h-6" />
          </button>
        </div>
        <div onClick={onclickOfCard}>
          <div className="flex justify-between gap-4">
            <div className={`h-16 rounded-2xl px-3 w-full justify-start items-center`} style={{ backgroundColor: vitalBg }}>
              <p className={`${vitalTextColor} font-bold text-[24px]`}>{progressValue}</p>
              <p className={`${vitalTextColor} text-base`}>
                Unit: <span className={`${vitalTextColor} text-sm font-semibold`}>{progressUnit}</span>
              </p>
            </div>
            <div className="h-16 rounded-2xl w-full items-center flex">
              <RangeMeter leftRange={startRange} rightRange={endRange} value={activeRange} />
            </div>
          </div>
          <div className="flex justify-between">
            <div>
              <p className="text-text-ternary text-sm">
                Logged on <span className="font-semibold">{loggedDate}</span>
              </p>
            </div>
            <div>
              <p className="text-text-secondary text-sm">{status}</p>
            </div>
          </div>
        </div>
      </Card>
    </div>
  );
};
export default ProgressResultDetailsCard;
