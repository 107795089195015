import React, { useMemo, useState } from "react";
import { toast } from "react-toastify";
import { useSelector, useDispatch } from "react-redux";

import Card from "src/components/atoms/Card/Card";
import CommonMealHeaderCard from "./CommonMealHeaderCard";
import DietOption from "./DietOption";
import FoodItems from "./FoodItems";
import BottomSheetSelect from "src/components/atoms/BottomSheetSelect";
import Button from "src/components/atoms/Button/Button";

import BreakfastIcon from "../../../../assets/icons/meals/breakfast.svg";
import LunchIcon from "../../../../assets/icons/meals/lunch.svg";
import SnacksIcon from "../../../../assets/icons/meals/snacks.svg";
import DinnerIcon from "../../../../assets/icons/meals/dinner.svg";

import { RootState } from "src/store";
import { IMealType } from "../../../../types/foodDiary";
import {
  logFoodItemRequest,
  updateSelectedOption,
} from "src/store/slices/foodDiarySlice";
import { dateConverstion } from "src/helpers/date";
import { useNavigate } from "react-router-dom";
import { bindQueryParams } from "src/helpers/common";

interface Props {
  mealData: IMealType;
}

const DietPlanMealCard: React.FC<Props> = ({ mealData }) => {
  const { dietPlan } = useSelector((state: RootState) => state.foodDiary);
  const { user } = useSelector((state: RootState) => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [openDeleteBottomSheet, setOpenDeleteBottomSheet] = useState<any>(null);

  const mealMessageAndIcon = useMemo(() => {
    switch (mealData.key) {
      case "breakfast":
        return {
          message: "Breakfast is a passport to morning.",
          icon: BreakfastIcon,
        };
      case "dinner":
        return {
          message: "Dinner is a passport to better sleep.",
          icon: DinnerIcon,
        };
      case "lunch":
        return {
          message: "Lunch is a passport to noon.",
          icon: LunchIcon,
        };
      case "morning_snack":
        return {
          message: "Morning Snack is a passport to noon.",
          icon: LunchIcon,
        };
      case "evening_snack":
        return {
          message: "Evening Snack is a passport to noon.",
          icon: LunchIcon,
        };
      default:
        return {
          message: "Snacks is a passport to evening.",
          icon: SnacksIcon,
        };
    }
  }, [mealData]);

  const buttonPressEvents = (
    type: "consume" | "abstain" | "edit" | "delete" | "add" | "option",
    item: any
  ) => {
    if (type === "add") {
      const optionID = dietPlan.selectedOptions.find(
        (item) => item.meal_types_id === mealData.meal_types_id
      );
      let payload: any = {
        mealTypeId: mealData.meal_types_id,
        mealName: mealData.meal_type,
        isFreeUser: !dietPlan?.data?.health_coach_id,
      };
      if (optionID?.option_id) {
        payload = {
          ...payload,
          mealOptionId: optionID?.option_id ?? [],
        };
      }
      navigate(bindQueryParams("/diet/search-food", payload));
    } else if (type === "option") {
      const payload = {
        meal_types_id: mealData.meal_types_id,
        option_id: Number(item),
      };
      dispatch(updateSelectedOption(payload));
    } else if (type === "edit") {
      let payload: any = {
        mealTypeId: mealData?.meal_types_id,
        foodItemId: item?.food_item_id.toString(),
        foodName: item?.food_name,
        unitOptionId: (item?.unit_option_id ?? item?.unit_id).toString(),
        quantity: item?.quantity.toString(),
        type: item?.type,
        foodItemLogId: item?.patient_food_item_logs_id,
        buttonType: "Update",
        is_active: item?.is_active,
        mealName: mealData?.meal_type,
        unitName: item?.UNIT_NAME ?? item?.unit_name,
        toDietScreen: true,
      };
      if (!!item.diet_plan_meal_options_rel_id) {
        payload = {
          ...payload,
          diet_plan_meal_options_rel_id: item.diet_plan_meal_options_rel_id,
        };
      }
      navigate(bindQueryParams("/diet/search-food-result", payload));
      //   navigate('DietDetail', payload);
    } else {
      if (
        type === "delete" &&
        item.type === "diet_plan" &&
        !!dietPlan?.data?.health_coach_id
      ) {
        // toast.hideAll();
        toast.error(
          "Unfortunately, you can not delete this food item since it was recommended by your nutritionist.",
          {
            theme: "colored",
          }
        );
        return;
      }
      let payload: any = {
        meal_types_id: mealData?.meal_types_id,
        food_item_id: item.food_item_id.toString(),
        food_name: item.food_name,
        quantity: item.quantity.toString(),
        unit_option_id: (item?.unit_option_id ?? item?.unit_id).toString(),
        patient_id: user.data.patientID,
        achieved_datetime: dateConverstion(
          dietPlan.date,
          "YYYY-MM-DD hh:mm:ss",
          "YYYY-MM-DD hh:mm:ss",
          true
        ),
        type: item.type,
      };
      if (type === "delete") {
        payload = {
          ...payload,
          is_deleted: "Y",
        };
      } else {
        payload = {
          ...payload,
          is_active: type === "consume" ? "Y" : "N",
        };
      }
      if (!!item?.patient_food_item_logs_id) {
        payload = {
          ...payload,
          patient_food_item_logs_id: item?.patient_food_item_logs_id,
        };
      }
      if (!!item?.diet_plan_meal_options_rel_id) {
        payload = {
          ...payload,
          diet_plan_meal_options_rel_id: item?.diet_plan_meal_options_rel_id,
        };
      }
      if (type === "delete") {
        setOpenDeleteBottomSheet({
          onConfirm: () => {
            setOpenDeleteBottomSheet(null);
            updateFoodItem(payload, type, item);
          },
          onCancel: () => {
            setOpenDeleteBottomSheet(null);
          },
        });
        return;
      } else {
        updateFoodItem(payload, type, item);
      }
    }
  };

  const updateFoodItem = (
    payload: any,
    type: "consume" | "abstain" | "edit" | "delete" | "add" | "option",
    extraData: any
  ) => {
    const tempPayload = {
      payload,
      extraData,
      type,
    };
    // showLoader();
    new Promise((resolve, reject) => {
      dispatch(
        logFoodItemRequest({
          payload: tempPayload,
          resolve,
          reject,
        })
      );
    })
      .then(() => {
        // dispatch(actions.healthSectionRefresh({isHealthRefresh: true}));
      })
      .finally(() => {
        // hideLoader();
      });
  };

  const addIconVisible = useMemo(() => {
    const filterOption =
      mealData?.options?.filter((item) => item.option !== "manual") ?? [];
    const anyOptionConsumed = filterOption.some((item) =>
      item.data.some((otp) => otp.is_active === "Y")
    );

    const selectedOptionHasConsumed = dietPlan?.selectedOptions?.find(
      (item) => item.meal_types_id === mealData?.meal_types_id
    );

    if (selectedOptionHasConsumed && anyOptionConsumed) {
      return filterOption
        .find((item) =>
          selectedOptionHasConsumed.option_id.includes(item.option)
        )
        ?.data.some((it) => it.is_active === "Y");
    }
    return !anyOptionConsumed;
  }, [dietPlan?.selectedOptions, mealData]);

  const optionTip = useMemo(() => {
    const filterOption =
      mealData?.options?.filter((item) => item.option !== "manual") ?? [];
    const selectedOption = dietPlan?.selectedOptions?.find(
      (item) => item.meal_types_id === mealData?.meal_types_id
    );

    if (selectedOption) {
      return (
        filterOption.find((item) =>
          selectedOption.option_id.includes(item.option)
        )?.tips ?? ""
      );
    }
    return "";
  }, [dietPlan?.selectedOptions, mealData]);

  const modifyedMealItem = useMemo(() => {
    const mealFound = dietPlan?.selectedOptions?.find(
      (opt) => opt.meal_types_id === mealData?.meal_types_id
    );
    if (!mealFound) return mealData?.options?.[0]?.data ?? [];

    const combinedData =
      mealData?.options?.flatMap((item) =>
        mealFound?.option_id?.includes(item.option)
          ? item.data.length
            ? item.data
            : []
          : []
      ) ?? [];

    return combinedData.sort((a, b) =>
      a.type === "manual" ? -1 : b.type === "manual" ? 1 : 0
    );
  }, [dietPlan?.selectedOptions, mealData]);

  return (
    <Card className="p-0 mb-2 bg-white">
      <CommonMealHeaderCard
        start_time={mealData?.start_time}
        end_time={mealData?.end_time}
        mealIcon={mealMessageAndIcon?.icon}
        meal_type={mealData?.meal_type}
        onPressAdd={() => buttonPressEvents("add", mealData)}
        addIconVisible={addIconVisible}
        consumed_calories={Math.round(
          Number(mealData?.totalConsumeCalories ?? 0) ?? 0
        )}
        total_calories={Math.round(Number(mealData?.totalCalories ?? 0) ?? 0)}
      />
      <DietOption
        meal_type_id={mealData.meal_types_id}
        optionData={mealData.options ?? []}
        onPressOption={(option) => buttonPressEvents("option", option)}
      />
      <FoodItems
        message={mealMessageAndIcon?.message}
        foodItems={modifyedMealItem}
        buttonPressEvents={buttonPressEvents}
        buttonVisible={addIconVisible}
        freeUser={!dietPlan?.data?.health_coach_id}
      />
      {!!optionTip ? (
        <div className="border border-gray-300 rounded-2xl px-4 py-2 mx-4 my-2">
          <p className="text-xs text-text-subtitle">{optionTip}</p>
        </div>
      ) : null}

      <BottomSheetSelect
        open={!!openDeleteBottomSheet}
        close={() => setOpenDeleteBottomSheet(null)}
      >
        <section className="p-4 pb-6">
          <p className="text-md text-center mb-6">
            Are you sure you want to delete this food item from your meal?
          </p>
          <div className="flex items-center gap-4">
            <Button
              variant="secondary"
              label="No"
              className="w-full"
              onClick={openDeleteBottomSheet?.onCancel}
            />
            <Button
              variant="primary"
              label="Yes"
              className="w-full"
              onClick={openDeleteBottomSheet?.onConfirm}
            />
          </div>
        </section>
      </BottomSheetSelect>
    </Card>
  );
};

export default DietPlanMealCard;
