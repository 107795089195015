import React from "react";

import Card from "src/components/atoms/Card/Card";
import ProgressIcon from "src/components/atoms/Icons/ProgressIcon";

import RIGHTICON from "../../../../assets/icons/progress/view-more-icon.svg";

interface Props {
  image: string;
  progressTitle: string;
  progressSubText: string;
  vitalBg: string;
  vitalTextColor: string;
  vitalLogged: number | string;
  handleOnclick?: () => void;
}

const ProgressDetailCard: React.FC<Props> = ({ image, progressTitle, progressSubText, vitalBg, vitalTextColor, vitalLogged, handleOnclick }) => {
  return (
    <Card className="p-4 bg-background !rounded-3xl">
      <div className="flex flex-col gap-4">
        <div className="flex gap-3 flex-shrink-0">
          <ProgressIcon bgColor="bg-background-ternary" image={image} className="rounded-[17.5px] w-[70px] h-[70px]" />
          <div className="flex flex-col gap-1">
            <div className="font-semibold text-text-primary text-base">{progressTitle}</div>
            <div className="text-sm text-text-secodary font-poppins">{progressSubText}</div>
          </div>
        </div>
        <div className="flex justify-between items-center">
          <div className={`${vitalBg} rounded-3xl px-3 py-1`}>
            <div className={`text-xs ${vitalTextColor} font-medium leading-[18px]`}>{vitalLogged} vitals logged</div>
          </div>
          <button onClick={handleOnclick}>
            <ProgressIcon image={RIGHTICON} className="w-6 h-6 cursor-pointer" />
          </button>
        </div>
      </div>
    </Card>
  )
}

export default ProgressDetailCard
