import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import Input from "../../components/atoms/Input/Input";
import useDebounce from "src/hooks/useDebounce";
import useQueryParams from "src/hooks/useQueryParams";

import { RootState } from "src/store";
import {
  foodDiaryRecentSearchItemRequest,
  getFoodItemsRequest,
  onClearSearchUpdateFoodItem,
  updateRecentSearchRequest,
} from "src/store/slices/foodDiarySlice";
import SearchResult from "src/components/organisms/search-food-items/SearchResult";
import { bindQueryParams } from "src/helpers/common";

const SearchFoodPage: React.FC = () => {
  const [searchText, setSearchText] = useState("");
  const [page, setPage] = useState<number>(1);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const queryParams = useQueryParams();

  const { foodItems, recentFoodItems, dietPlan } = useSelector(
    (state: RootState) => state.foodDiary
  );

  // Use the custom debounce hook
  const debouncedText = useDebounce(searchText, 300);

  useEffect(() => {
    if (!dietPlan?.data) navigate("/diet");
    getSearchHistory();
  }, []);

  // Fetch data when searchText changes
  useEffect(() => {
    if (debouncedText) {
      resetSearchResults(debouncedText);
    }
  }, [debouncedText]);

  // Reset search results and fetch the first page
  const resetSearchResults = (text: string) => {
    setPage(1);
    fetchFoodItems(1, text);
  };

  const getSearchHistory = () => {
    const payload = {
      queryParams: {
        search_context: "FOOD_ITEM",
        limit: 4,
      },
    };
    new Promise((resolve, reject) => {
      dispatch(
        foodDiaryRecentSearchItemRequest({
          payload,
          resolve,
          reject,
        })
      );
    });
  };

  // Fetch food items API
  const fetchFoodItems = (page: number, text: string) => {
    const payload = {
      page: page.toString(),
      food_name: text,
    };

    new Promise((resolve, reject) => {
      dispatch(
        getFoodItemsRequest({
          payload,
          resolve,
          reject,
        })
      );
    });
  };

  const handlePressPlus = async (data: any) => {
    // add item in recent search or not
    const itemFoundInRecentSearchItem = (recentFoodItems?.data ?? [])?.find(
      (item) => item?.FOOD_ID === data?.FOOD_ID
    );
    if (!itemFoundInRecentSearchItem) {
      const payload = {
        search_context: "FOOD_ITEM",
        search_query: JSON.stringify(data),
      };
      new Promise((resolve, reject) => {
        dispatch(
          updateRecentSearchRequest({
            payload,
            resolve,
            reject,
          })
        );
      });
    }

    // analytics.trackEvent(
    //   Constants.EVENT_NAME.FOOD_DIARY.USER_SEARCHED_FOOD_DISH,
    //   {
    //     meal_types: queryParams?.mealName,
    //     date: dietPlan.date,
    //     food_item_name: data?.FOOD_NAME ?? data?.food_name ?? "",
    //   }
    // );

    let payload: any = {
      mealTypeId: queryParams?.mealTypeId,
      foodItemId: data?.FOOD_ID ?? data?.food_item_id,
      foodName: data?.FOOD_NAME ?? data?.food_name,
      unitOptionId: data?.UNIT_ID ?? data?.unit_id,
      mealName: queryParams?.mealName ?? "",
      unitName: data?.UNIT_NAME ?? data?.unit_name,
      buttonType: "Add",
      toDietScreen: false,
    };
    if (queryParams.isFreeUser) {
      const foodItemFoundInDiet: any = dietPlan.meals
        .find((meal) => meal.meal_types_id === queryParams?.mealTypeId)
        ?.data?.find(
          (foodItem) =>
            foodItem?.food_item_id === (data?.FOOD_ID ?? data?.food_item_id)
        );
      if (!!foodItemFoundInDiet) {
        payload = {
          ...payload,
          quantity: (foodItemFoundInDiet?.quantity ?? 0).toString(),
          type: foodItemFoundInDiet?.type ?? "manual",
          is_active: foodItemFoundInDiet.is_active,
          buttonType: "Update",
          foodItemLogId: foodItemFoundInDiet?.patient_food_item_logs_id,
        };
      }
      //  foodItemLogId , quantity ,type ,is_active
      // const checkItemIsThere =
    } else {
      const mealTypeId = queryParams?.mealTypeId;
      const mealOptionIds = queryParams.mealOptionId;
      const foodItemId = data?.FOOD_ID ?? data?.food_item_id;

      const selectedMeal = dietPlan?.meals.find(
        (meal) => meal.meal_types_id === mealTypeId
      );

      if (selectedMeal) {
        const relevantOptions = selectedMeal.options?.filter((otp) =>
          mealOptionIds?.includes(otp.option)
        );

        const findOption = relevantOptions?.find((option) =>
          option.data.some((item) => item.food_item_id === foodItemId)
        );

        if (findOption) {
          const findFoodItem = findOption.data.find(
            (item) => item.food_item_id === foodItemId
          );

          if (findFoodItem) {
            payload = {
              ...payload,
              quantity: findFoodItem.quantity?.toString() ?? "0",
              type: findFoodItem.type ?? "manual",
              is_active: findFoodItem.is_active,
              buttonType: "Update",
              ...(findFoodItem.patient_food_item_logs_id && {
                foodItemLogId: findFoodItem.patient_food_item_logs_id,
              }),
              ...(findOption.option !== "manual" && {
                mealOptionId: findOption.option,
              }),
              ...(findOption.option !== "manual" && {
                diet_plan_meal_options_rel_id:
                  findFoodItem.diet_plan_meal_options_rel_id,
              }),
            };
          }
        }
      }
    }
    navigate(bindQueryParams("/diet/search-food-result", payload));
    // navigation.navigate('DietDetail', payload);
  };

  // Infinite scroll handler
  const handleScroll = (event: React.UIEvent<HTMLDivElement, UIEvent>) => {
    const target = event.target as HTMLDivElement;

    if (
      target.scrollHeight - target.scrollTop <= target.clientHeight + 100 &&
      !foodItems.isLoading &&
      !foodItems.isLastPageReached
    ) {
      const nextPage = page + 1;
      setPage(nextPage);
      fetchFoodItems(nextPage, debouncedText);
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
  };

  const handleCrossBtnClick = () => {
    setSearchText("");
    setPage(1);
    dispatch(onClearSearchUpdateFoodItem());
  };

  return (
    <div className="bg-background h-screen flex flex-col">
      {/* Header */}
      <div className="flex items-center gap-2 p-4">
        <button
          className="z-1 px-2 py-1 -top-1 right-0"
          onClick={() => navigate("/diet")}
        >
          <i className="fa fa-chevron-left" />
        </button>
        <Input
          name="search"
          className="w-full"
          onChange={handleChange}
          placeholder="Search your food item"
          value={searchText}
          showCrossBtn={true}
          handleCrossBtnClick={handleCrossBtnClick}
          autoFocus={true}
        />
      </div>

      {/* Scrollable Content */}
      <div
        className="flex-1 overflow-y-auto"
        // onScroll={handleScroll} // Detect scroll event
      >
        <SearchResult
          title={foodItems?.title}
          searchText={debouncedText}
          isLoading={foodItems?.isLoading}
          data={foodItems?.data}
          page={page}
          isLastPageReached={foodItems?.isLastPageReached}
          onScroll={handleScroll}
          onClickAddIcon={handlePressPlus}
        />
      </div>
    </div>
  );
};

export default SearchFoodPage;
