import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState } from "src/store";

import { ProgressPointer } from "../Icons/ProgressPointer";

interface ProgressRangeMeterProps {
  leftRange: number | 0;
  rightRange: number | 0;
  value: number | 0;
}

const ProgressRangeMeter: React.FC<ProgressRangeMeterProps> = ({ leftRange, rightRange, value }) => {
  const [arrowPosition, setArrowPosition] = useState<number>(0);
  const theme = useSelector((state: RootState) => state.theme);

  useEffect(() => {
    if (leftRange !== null && rightRange !== null && value !== null) {
      const rangeDifference = rightRange - leftRange
      const position = ((value - leftRange) / rangeDifference) * 100
      setArrowPosition(position)
    }
  }, [leftRange, rightRange, value])

  return (
    <div className="flex flex-col items-center w-full">
      <p className="text-sm text-text-ternary">Ideal Range</p>
      <div className="relative w-full mt-4">
        <div className=" ">
          <div className="w-full flex flex-row gap-1">
            <div className="h-1 w-[40%] bg-color-extra-light-red rounded-full"> </div>
            <div className="h-1 w-full bg-color-extra-light-green rounded-full"></div>
            <div className="h-1 w-[40%]  bg-color-extra-light-red rounded-full"></div>
          </div>
          <div
            className="absolute top-[-13px] left-1/2 transform -translate-x-1/2 "
            style={{
              left: `${arrowPosition}%`,
            }}
          >
            <ProgressPointer color={theme.colors["--color-error"]} />
          </div>
        </div>
      </div>
      <div className="flex justify-around w-full mt-2">
        <p className="text-text-ternary text-sm">{leftRange !== null ? leftRange : "N/A"}</p>
        <p className="text-text-ternary text-sm">{rightRange !== null ? rightRange : "N/A"}</p>
      </div>
    </div>
  )
}

export default ProgressRangeMeter;
