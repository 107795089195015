import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "src/store";
import { useDispatch } from "react-redux";

import { Header } from "src/components/molecules/Header";
import ExpandableDivs from "src/components/atoms/Layout/ExpandingLayout";
import ProgressRecordedDataCard from "src/components/molecules/progress/ProgressRecordedDataCard";
import { progressRedingLogsRequest } from "src/store/slices/progressSlice";

const ProgressRecordedDataPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { selectedProgressMasterId } = useSelector((state: RootState) => state.progress);
  const { progressRedings } = useSelector((state: RootState) => state.progress);

  useEffect(() => {
    const payload = {
      readings_master_id: selectedProgressMasterId?.toString(),
      page: 1,
    };
    new Promise((resolve, reject) => {
      dispatch(
        progressRedingLogsRequest({
          payload,
          resolve,
          reject,
        })
      );
    });
  }, [selectedProgressMasterId]);
  console.log('progressRedings', progressRedings)

  return (
    <div className=" bg-background-light-blue h-screen">
      <ExpandableDivs
        showExpandBtn={false}
        component1={
          <div>
            <Header
              backButton={true}
              rightButton={false}
              title="All Recorded Data"
              className="bg-background-light-blue"
              onClickOfBackBtn={() => navigate(-1)}
            />
          </div>
        }
        component2={
          <div className="p-4 flex flex-col gap-4">
            <ProgressRecordedDataCard systolic={123} diastolic={85} recordedDate="21-NOV-2024" />
            <ProgressRecordedDataCard systolic={130} diastolic={90} recordedDate="22-NOV-2024" />
          </div>
        }
      />
    </div>
  );
};

export default ProgressRecordedDataPage;
