import React from "react";

import Card from "src/components/atoms/Card/Card";

interface RecordedDataCardProps {
  systolic: number;
  diastolic: number;
  recordedDate: string;
}

const ProgressRecordedDataCard: React.FC<RecordedDataCardProps> = ({ systolic, diastolic, recordedDate }) => {
  return (
    <Card className="!rounded-3xl">
      <div className="p-4 flex flex-row justify-between items-center">
        <div className="flex flex-col">
          <p className="text-text-primary font-bold">SYSTOLIC: {systolic}</p>
          <p className="text-text-primary font-bold">DIASTOLIC: {diastolic}</p>
        </div>
        <div>
          <p className="text-text-subtitle">{recordedDate}</p>
        </div>
      </div>
      <hr className="h-[1px] flex justify-center mx-4 bg-color-extra-light-gray " />
      <div className="p-4 flex flex-row justify-between items-center">
        <div className="flex flex-col">
          <p className="text-text-primary font-bold">SYSTOLIC: {systolic}</p>
          <p className="text-text-primary font-bold">DIASTOLIC: {diastolic}</p>
        </div>
        <div>
          <p className="text-text-subtitle">{recordedDate}</p>
        </div>
      </div>
    </Card>
  )
}

export default ProgressRecordedDataCard;
