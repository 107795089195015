import React, {
  useEffect,
  useState,
  useMemo,
  useCallback,
  useRef,
} from "react";
import moment from "moment";
import { toast } from "react-toastify";

import exerciseApis from "src/api/exercise";
import { Exercise } from "src/types/exercise";

import BottomSheetSelect from "src/components/atoms/BottomSheetSelect";
import Button from "src/components/atoms/Button/Button";
import TextInput from "src/components/atoms/TextInput";
import InputAnimatedV2 from "src/components/atoms/InputAnimatedV2";
import WeelPicker from "src/components/atoms/WheelPicker";
import SpinnerLoader from "src/components/atoms/Loader/SpinnerLoader";
import Calendar from "src/components/atoms/Calendar";

import SearchIcon from "src/assets/icons/common/search-icon.svg";

const selections = {
  duration: Array.from({ length: 12 }, (_, i) => `${(i + 1) * 5} min`),
};
const hourSelections = {
  duration: Array.from({ length: 12 }, (_, i) => `${(i + 1) * 1}`),
};
const minuteSelections = {
  duration: Array.from({ length: 60 }, (_, i) => `${i * 1}`),
};
const offsetSelection = {
  duration: ["AM", "PM"],
};

type AddExercisePayload = {
  intensity_id?: string;
  achieved_value: string;
  goal_id: string;
  patient_sub_goal_id: string;
  start_time: string;
  end_time: string;
  achieved_datetime: string;
};

interface AddExerciseProps {
  setOpenBottomSheet: React.Dispatch<React.SetStateAction<boolean>>;
  goal_id: any;
  fetchLoggedExercise: any;
  selectedDate: Date;
}

const AddExercise: React.FC<AddExerciseProps> = ({
  setOpenBottomSheet,
  goal_id,
  fetchLoggedExercise,
  selectedDate: selectedCalanderDate,
}) => {
  const textInputRef = useRef<HTMLInputElement>(null);

  const [loading, setLoading] = useState(true);
  const [addExerciseLoading, setAddExerciseLoading] = useState(false);
  const [allExercises, setAllExercises] = useState<Exercise[]>([]);
  const [searchText, setSearchText] = useState("");
  const [selectedExercise, setSelectedExercise] = useState<Exercise | null>(
    null
  );

  const [modalState, setModalState] = useState({
    exerciseSheet: false,
    durationSheet: false,
    intensitySheet: false,
    startTimeSheet: false,
    dateSheet: false,
  });

  const [formData, setFormData] = useState({
    selectedDate: moment(new Date(selectedCalanderDate)).format("DD-MM-YYYY"),
    startTime: moment().format("hh:mm A"),
    selectedDuration: "",
    selectedIntensity: "",
  });
  const [selectedDate, setSelectedDate] = useState<string>(
    formData.selectedDate
  );
  const [timeFormData, setTimeFormData] = useState({
    hour: moment().format("h"),
    minute: moment().format("mm"),
    offset: moment().format("A"),
  });
  const [selectedDuration, setSelectedDuration] = useState("5 min");
  const [selectedIntensity, setSelectedIntensity] = useState<string>("");

  const disabledDate = useCallback(
    (current: any) => current && current.valueOf() > Date.now(),
    []
  );

  const fetchAllExercises = async () => {
    try {
      setLoading(true);
      const response = await exerciseApis.fetchExercises();
      if (response.code != 1) {
        throw new Error(response.msg || "Internal server error");
      }
      setAllExercises(response.data || []);
    } catch (error) {
      console.error("Error fetching exercises:", error);
    } finally {
      setLoading(false);
    }
  };

  const filteredExercises = useMemo(() => {
    if (!searchText) return allExercises;
    return allExercises.filter((exe) =>
      exe.exercise_name.toLowerCase().includes(searchText.toLowerCase())
    );
  }, [searchText, allExercises]);

  const handleOpenSheet = (sheetName: keyof typeof modalState) => {
    setModalState((prev) => ({ ...prev, [sheetName]: true }));
    if (sheetName === "exerciseSheet") {
      setTimeout(() => {
        textInputRef.current?.blur();
      }, 100);
    }
  };

  const handleCloseSheet = (sheetName: keyof typeof modalState) =>
    setModalState((prev) => ({ ...prev, [sheetName]: false }));

  const handleInputChange = (
    key: keyof typeof formData,
    value: string | number
  ) => {
    setFormData((prev) => ({ ...prev, [key]: value }));
  };

  const handleStartTimeInputChange = (
    key: keyof typeof timeFormData,
    value: string | number
  ) => {
    setTimeFormData((prev) => ({ ...prev, [key]: value }));
  };

  const handleSubmit = async () => {
    try {
      if (
        !formData.selectedDate ||
        !formData.startTime ||
        !selectedExercise?.exercise_name ||
        !formData.selectedDuration ||
        !!(
          selectedExercise?.exercise_intensity?.length &&
          !formData.selectedIntensity
        ) ||
        addExerciseLoading
      )
        return;
      const { selectedDate, startTime, selectedDuration, selectedIntensity } =
        formData;

      const startMoment = moment(
        `${selectedDate} ${startTime}`,
        "DD-MM-YYYY hh:mm A"
      );
      const endMoment = startMoment
        .clone()
        .add(parseInt(selectedDuration.split(" ")[0]), "minutes");

      const payload: AddExercisePayload = {
        intensity_id: selectedExercise?.exercise_intensity?.find(
          (i) => i.intensity === selectedIntensity
        )?.exercise_intensity_rel_id,
        achieved_value: selectedDuration.split(" ")[0],
        goal_id: goal_id,
        patient_sub_goal_id: `${selectedExercise?.exercise_value ?? -1}`,
        start_time: startMoment.format("YYYY-MM-DD HH:mm:ss"),
        end_time: endMoment.format("YYYY-MM-DD HH:mm:ss"),
        achieved_datetime: startMoment.format("YYYY-MM-DD HH:mm:ss"),
      };

      setAddExerciseLoading(true);
      const response = await exerciseApis.logExercise(payload);
      if (response.code != 1) {
        throw new Error(response.msg || "Internal server error");
      }
      toast.success("Added exercise successfully.", {
        theme: "colored",
      });

      fetchLoggedExercise();

      setOpenBottomSheet(false);
    } catch (error) {
      console.error("Error submitting exercise:", error);
      toast.error("Error submitting exercise.", {
        theme: "colored",
      });
    } finally {
      setAddExerciseLoading(false);
    }
  };

  useEffect(() => {
    fetchAllExercises();
  }, []);

  return (
    <>
      <section className="p-4">
        <h1 className="text-xl font-bold text-text-secondary">Add Exercise</h1>
      </section>
      <hr className="w-full h-0.5 bg-background" />
      <section className="p-4 flex flex-col gap-3 pb-5">
        {/* <DatePicker
          label="Select a Date"
          defaultValue={dayjs(formData.selectedDate, "DD-MM-YYYY")}
          value={dayjs(formData.selectedDate, "DD-MM-YYYY")}
          onChange={(_, date) =>
            handleInputChange("selectedDate", date as string)
          }
          disabledDate={disabledDate}
        /> */}
        {/* <TimePicker
          label="Select Time"
          value={dayjs(formData.startTime, "hh:mm A")}
          onChange={(_, time) => handleInputChange("startTime", time as string)}
        /> */}

        <InputAnimatedV2
          id="exerciseDate"
          label="Select a Date"
          value={moment(formData.selectedDate, "DD-MM-YYYY").format(
            "DD-MM-YYYY"
          )}
          handleOnClick={() => {
            handleOpenSheet("dateSheet");
          }}
        />
        <InputAnimatedV2
          id="startTime"
          label="Select Time"
          value={formData.startTime}
          handleOnClick={() => handleOpenSheet("startTimeSheet")}
        />
        <InputAnimatedV2
          id="Exercise"
          label="Select Exercise"
          value={selectedExercise?.exercise_name || ""}
          handleOnClick={() => handleOpenSheet("exerciseSheet")}
        />
        <InputAnimatedV2
          id="Duration"
          label="Select Duration"
          value={formData.selectedDuration}
          handleOnClick={() => handleOpenSheet("durationSheet")}
        />
        {!!selectedExercise?.exercise_intensity?.length && (
          <InputAnimatedV2
            id="Intensity"
            label="Select Exercise Intensity/Type"
            value={formData.selectedIntensity}
            handleOnClick={() => handleOpenSheet("intensitySheet")}
          />
        )}
        <Button
          label={!addExerciseLoading ? "Add" : ""}
          type="submit"
          disabled={
            !formData.selectedDate ||
            !formData.startTime ||
            !selectedExercise?.exercise_name ||
            !formData.selectedDuration ||
            !!(
              selectedExercise?.exercise_intensity?.length &&
              !formData.selectedIntensity
            ) ||
            addExerciseLoading
          }
          onClick={handleSubmit}
          loading={addExerciseLoading}
          className="mt-2 h-12"
        />
      </section>

      {/* Bottom Sheet: Select Exercise */}
      {modalState.exerciseSheet && (
        <BottomSheetSelect
          open={modalState.exerciseSheet}
          close={() => handleCloseSheet("exerciseSheet")}
        >
          <section className="p-4">
            <h1 className="text-xl font-bold text-text-secodary">
              Select Exercise
            </h1>
          </section>

          <hr className="h-[2px] bg-background" />

          <section>
            <div className="px-4">
              <TextInput
                ref={textInputRef}
                placeholder="Enter exercise name"
                leftIcon={
                  <img src={SearchIcon} alt="Search Icon" className="w-5 h-5" />
                }
                onChange={(e) => setSearchText(e.target.value)}
                value={searchText}
              />
            </div>

            <section className="h-[40vh] overflow-scroll px-4">
              {!loading ? (
                <ul>
                  {filteredExercises.map((exe) => (
                    <li
                      key={exe.exercise_master_id}
                      className=" my-4 text-text-secodary"
                      onClick={() => {
                        setSelectedExercise(exe);
                        setSelectedIntensity(
                          exe?.exercise_intensity[0]?.intensity
                        );
                        handleCloseSheet("exerciseSheet");
                      }}
                    >
                      {exe.exercise_name}
                    </li>
                  ))}
                </ul>
              ) : (
                <SpinnerLoader />
              )}
            </section>
          </section>
        </BottomSheetSelect>
      )}

      {/* Bottom Sheet: Select Duration */}
      {modalState.durationSheet && (
        <BottomSheetSelect
          open={modalState.durationSheet}
          close={() => handleCloseSheet("durationSheet")}
        >
          <section className="p-4">
            <h1 className="text-xl font-bold text-text-secodary">
              Select Duration
            </h1>
          </section>

          <hr className="w-full h-[2px] bg-background" />

          <section className="">
            <div className="p-4 text-text-secodary text-base">
              Choose Duration of exercise you performed
            </div>

            <section className="relative h-[35vh] flex items-center justify-center">
              <div className=" flex items-center justify-center">
                <WeelPicker
                  selections={selections}
                  value={{ duration: selectedDuration }}
                  onChange={(value) => setSelectedDuration(value.duration)}
                />
              </div>
              <div className="w-[40px] h-[40px] bg-primary absolute rotate-45 right-[-20px]" />
              <div className="w-[40px] h-[40px] bg-primary absolute rotate-45 left-[-20px]" />
            </section>

            <div className="p-4">
              <Button
                label="Add"
                type="submit"
                className="w-full"
                onClick={() => {
                  handleInputChange("selectedDuration", selectedDuration);
                  handleCloseSheet("durationSheet");
                }}
              />
            </div>
          </section>
        </BottomSheetSelect>
      )}

      {/* Bottom Sheet: Select Intensity */}
      {modalState.intensitySheet && (
        <BottomSheetSelect
          open={modalState.intensitySheet}
          close={() => handleCloseSheet("intensitySheet")}
        >
          <section className="p-4">
            <h1 className="text-xl font-bold text-text-secodary">
              Select Duration
            </h1>
          </section>

          <hr className="w-full h-[2px] bg-background" />

          <section className="">
            <div className="p-4 text-text-secodary text-base">
              Choose Duration of exercise you performed
            </div>

            <section className="relative h-[35vh] flex items-center justify-center">
              <div className=" flex items-center justify-center">
                <WeelPicker
                  selections={{
                    intensity:
                      selectedExercise?.exercise_intensity.map(
                        (i) => i.intensity
                      ) || [],
                  }}
                  value={{ intensity: selectedIntensity }}
                  onChange={(value) => {
                    setSelectedIntensity(value.intensity);
                  }}
                />
              </div>
              <div className="w-[40px] h-[40px] bg-primary absolute rotate-45 right-[-20px]" />
              <div className="w-[40px] h-[40px] bg-primary absolute rotate-45 left-[-20px]" />
            </section>

            <div className="p-4">
              <Button
                label="Add"
                type="submit"
                className="w-full"
                onClick={() => {
                  handleInputChange("selectedIntensity", selectedIntensity);
                  handleCloseSheet("intensitySheet");
                }}
              />
            </div>
          </section>
        </BottomSheetSelect>
      )}

      {/* Bottom Sheet: Select start time */}
      {modalState.startTimeSheet && (
        <BottomSheetSelect
          open={modalState.startTimeSheet}
          close={() => handleCloseSheet("startTimeSheet")}
        >
          <section className="p-4">
            <h1 className="text-xl font-bold text-text-secodary">
              Select Start Time
            </h1>
          </section>

          <hr className="w-full h-[2px] bg-background" />

          <section className="">
            <div className="p-4 text-text-secondary text-base">
              Choose start time of exercise you performed
            </div>

            <section className="relative h-[35vh] flex items-center justify-center">
              <div className=" flex gap-10 items-center justify-center">
                <div>
                  <p className="text-text-subtitle text-xl font-medium">
                    Hours
                  </p>
                  <WeelPicker
                    selections={hourSelections}
                    value={{ duration: timeFormData.hour }}
                    onChange={(value) =>
                      handleStartTimeInputChange("hour", value.duration)
                    }
                  />
                </div>
                <div>
                  <p className="text-text-subtitle text-xl font-medium">
                    Minutes
                  </p>
                  <WeelPicker
                    selections={minuteSelections}
                    value={{ duration: timeFormData.minute }}
                    onChange={(value) =>
                      handleStartTimeInputChange("minute", value.duration)
                    }
                  />
                </div>
                <div>
                  <p className="text-text-subtitle text-xl font-medium">
                    &nbsp;
                  </p>
                  <WeelPicker
                    selections={offsetSelection}
                    value={{ duration: timeFormData.offset }}
                    onChange={(value) =>
                      handleStartTimeInputChange("offset", value.duration)
                    }
                  />
                </div>
              </div>
              <div className="w-[40px] h-[40px] bg-primary absolute rotate-45 right-[-20px]" />
              <div className="w-[40px] h-[40px] bg-primary absolute rotate-45 left-[-20px]" />
            </section>

            <div className="p-4">
              <Button
                label="Select Time"
                type="submit"
                className="w-full"
                onClick={() => {
                  handleInputChange(
                    "startTime",
                    `${timeFormData.hour}:${timeFormData.minute} ${timeFormData.offset}`
                  );
                  handleCloseSheet("startTimeSheet");
                }}
              />
            </div>
          </section>
        </BottomSheetSelect>
      )}

      {/* Bottom Sheet: Select date */}
      {modalState.dateSheet && (
        <BottomSheetSelect
          open={modalState.dateSheet}
          close={() => handleCloseSheet("dateSheet")}
        >
          <section className="p-4">
            <h1 className="text-xl font-bold text-text-secodary">
              Select a Date
            </h1>
          </section>

          <hr className="w-full h-[2px] bg-background" />

          <section className="">
            <div className="p-4 text-text-secodary text-base">
              Choose date of exercise you performed
            </div>

            <section className="relative h-full flex items-center justify-center">
              <div className=" flex gap-10 items-center justify-center">
                <Calendar
                  selectedDate={
                    new Date(moment(selectedDate).format("DD-MM-YYYY"))
                  }
                  onSelect={(date) => {
                    if (date > new Date())
                      toast.error("You can not select future date!");
                    else setSelectedDate(moment(date).format("DD-MM-YYYY"));
                  }}
                  showExpandArrow={false}
                  fullView={true}
                  className="bg-white"
                  disabledDate={disabledDate}
                />
              </div>
            </section>

            <div className="p-4">
              <Button
                label="Select Date"
                type="submit"
                className="w-full"
                onClick={() => {
                  handleInputChange(
                    "selectedDate",
                    moment(selectedDate, "DD-MM-YYYY").format("DD-MM-YYYY")
                  );
                  handleCloseSheet("dateSheet");
                }}
              />
            </div>
          </section>
        </BottomSheetSelect>
      )}
    </>
  );
};

export default AddExercise;
