import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { RootState } from "src/store";
import DietExerciseCard from "../DietExerciseCard";
import GirlRunningImage from "src/assets/images/landing-page/exercise.png";
import { useNavigate } from "react-router-dom";

const ExerciseSection: React.FC<any> = ({ exerciseData }) => {
  const { healthInsights } = useSelector((state: RootState) => state.landing);
  const navigate = useNavigate();

  const onPressExercise = () => {
    navigate(`/exercise?goal_id=${exerciseData?.goal_master_id}`);
  };

  return (
    <DietExerciseCard
      data={exerciseData}
      titleText={"Exercise"}
      todaysAchievedValue={parseInt(exerciseData?.todays_achieved_value)}
      todaysAcheievedText={"Minutes done today"}
      description={"Track your workouts, one exercise at a time"}
      onPress={onPressExercise}
      rightImage={GirlRunningImage}
    ></DietExerciseCard>
  );
};

ExerciseSection.propTypes = {
  exerciseData: PropTypes.object.isRequired,
};

export default ExerciseSection;
