import React from "react";
import DietExerciseCard from "../DietExerciseCard";
// import { handleEvent } from "../../../helpers/analytics/Events/common";
// import analytics from "../../../helpers/analytics";
// import Navigation from "../../../helpers/Navigation";
import HealthyFoodImage from "src/assets/images/landing-page/healthy-food.png";
import { useNavigate } from "react-router-dom";

const DietSection = ({ diet }: any) => {
  const navigate = useNavigate();
  const onPressDiet = (type: string) => {
    navigate("/diet");
  };

  return (
    <DietExerciseCard
      data={diet}
      titleText={"Diet"}
      todaysAchievedValue={parseInt(diet?.todays_achieved_value)}
      todaysAcheievedText={"Calories consumed"}
      description={"Log your meals and track your nutrition"}
      onPress={onPressDiet}
      rightImage={HealthyFoodImage}
    ></DietExerciseCard>
  );
};

export default DietSection;
