import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  categoryPayload,
  progressRedingsResponse,
  ProgressResponce,
  ProgressState,
  selectedProgress,
  selectedProgressMasterId,
  updatedValue,
} from "src/types/progress";

const progressInitialState: ProgressState = {
  isLoading: false,
  error: "",
  allProgress: [],
  progressRedings: [],
  selectedProgress: null,
  selectedProgressMasterId: "",
};

const progressSlice = createSlice({
  name: "progress",
  initialState: progressInitialState,
  reducers: {
    setUpdatedValue(state, action: PayloadAction<updatedValue>) {
      state.selectedProgress?.data.map((progress) => {
        if (progress.readings_master_id == action.payload.master_id) {
          console.log("item  readings_master_id", progress);
          progress.reading_value = action.payload.value;
        }
      });
    },
    setSelectedProgress: (state, action: PayloadAction<selectedProgress>) => {
      state.selectedProgress = action.payload;
    },
    setSelectedDetailsMasterId: (state, action: PayloadAction<selectedProgressMasterId>) => {
      state.selectedProgressMasterId = action.payload.selectedProgressMasterId;
    },
    progressCategoryRequest: (state: ProgressState, action: PayloadAction<any>) => {
      state.isLoading = true;
      state.error = "";
    },
    progressCategorySuccess: (state: ProgressState, action: PayloadAction<ProgressResponce>) => {
      state.allProgress = [...(state.allProgress ?? []), ...(action.payload.data ?? [])];
      state.isLoading = true;
      // console.log("progressCategorySuccessprogressCategorySuccess", action);
    },
    progressCategoryError: (state: any, { payload: error }: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = error;
    },
    progressRedingLogsRequest: (state: ProgressState, action: PayloadAction<any>) => {
      state.isLoading = action.payload;
      state.error = "";
    },
    progressRedingLogsSuccess: (state: ProgressState, action: PayloadAction<progressRedingsResponse>) => {
      state.isLoading = true;
      state.progressRedings = action.payload.data;
    },
    progressRedingLogsError: (state: any, { payload: error }: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = error;
    },
    updatePatientReadingsRequest: (state: ProgressState, action: PayloadAction<any>) => {
      state.isLoading = true;
      state.error = "";
      console.log("updatePatientReadingsRequest", action.payload);
    },
    updatePatientReadingsSuccess: (state: ProgressState, action: PayloadAction<any>) => {
      state.isLoading = true;
      console.log("updatePatientReadingsSuccess", action);
    },
    updatePatientReadingsError: (state: any, { payload: error }: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = error;
      console.log("updatePatientReadingsError", error);
    },
  },
});

export default progressSlice.reducer;

export const {
  setUpdatedValue,
  progressCategoryRequest,
  progressCategorySuccess,
  progressCategoryError,
  setSelectedProgress,
  setSelectedDetailsMasterId,
  progressRedingLogsRequest,
  progressRedingLogsSuccess,
  progressRedingLogsError,
  updatePatientReadingsRequest,
  updatePatientReadingsSuccess,
  updatePatientReadingsError,
} = progressSlice.actions;
