import { methods, request } from "./base";

const ProgressApi = {
  CategoryWiseRedingData: async (payload: object) =>
    await request({
      method: methods.POST,
      route: "/goal_readings/category_wise_reading_data",
      priv: true,
      payload,
    }),

  fetchAllRedingsLogs: async (payload: object) =>
    await request({
      method: methods.POST,
      route: "/goal_readings/all_readings_logs",
      priv: true,
      payload,
    }),

  updatePatientReadings: async (payload: object) =>
    await request({
      method: methods.POST,
      route: "/goal_readings/update_patient_readings",
      priv: true,
      payload,
    }),
};

export default ProgressApi;
