import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { RootState } from "src/store";

import TextInput from "src/components/atoms/TextInput";
import ExpandableDivs from "src/components/atoms/Layout/ExpandingLayout";
import ProgressBackIcon from "src/components/atoms/ProgressBackIcon/ProgressBackIcon";
import ProgressDetailCard from "src/components/molecules/progress/ProgressDetailCard";
import { progressCategoryRequest, setSelectedProgress } from "src/store/slices/progressSlice";

import SearchIcon from "src/assets/icons/common/search-icon.svg";
import { error } from "console";

const ProgressPage: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const scrollRef = useRef<HTMLDivElement>(null);

  const { allProgress } = useSelector((state: RootState) => state.progress);

  const [page, setPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredData, setFilteredData] = useState(allProgress);
  const [showLoader, setshowLoader] = useState<boolean>(false)

  useEffect(() => {
    const payload = {
      page: page.toString(),
    };
    new Promise((resolve, reject) => {
      setshowLoader(true)
      dispatch(
        progressCategoryRequest({
          payload,
          resolve,
          reject,
        })
      );
    }).then((result) => {
      setshowLoader(false)
    }).catch((error) => {
      setshowLoader(false)
    }).finally(() => {
      setshowLoader(false)
    })
  }, [page]);

  const handleSearch = (term: string) => {
    setSearchTerm(term);
  };

  useEffect(() => {
    if (searchTerm.trim() === '') {
      setFilteredData(allProgress);
    } else {
      const filtered = allProgress?.filter((item) =>
        item.category_name.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredData(filtered);
    }
  }, [searchTerm, allProgress]);

  const handleOnclickProgress = (selectedProgress: any) => {
    dispatch(setSelectedProgress(selectedProgress));
    navigate("progress-details");
  };

  const handleScroll = () => {
    if (scrollRef.current && allProgress && allProgress.length > 0) {
      const { scrollTop, scrollHeight, clientHeight } = scrollRef.current;
      if (scrollTop + clientHeight >= scrollHeight - 4) {
        setPage((prevPage) => prevPage + 1);
      }
    }
  };

  return (
    <div className="h-screen bg-background-light-blue ">
      <ExpandableDivs
        showExpandBtn={false}
        component1={
          <div>
            <ProgressBackIcon onClick={() => navigate("/")} />
          </div>
        }
        component2={
          <div>
            <div>
              <div className="font-Playfair Display text-3xl font-semibold leading-10 px-4 pt-4 pb-3">Your Progress</div>
              <div className="px-4">
                <TextInput
                  value={searchTerm}
                  onChange={(e) => handleSearch(e.target.value)}
                  placeholder="Search by vital name"
                  readOnly={false}
                  leftIcon={<img src={SearchIcon} alt="Search Icon" className="w-5 h-5" />}
                />
              </div>
            </div>
            <div className="p-4 flex flex-col gap-4 overflow-y-auto" style={{ maxHeight: "80vh" }} onScroll={handleScroll} ref={scrollRef}>
              {filteredData && filteredData.length > 0 ? (
                filteredData.map((progress, index) => (
                  <ProgressDetailCard
                    key={index}
                    image={progress.icon_url}
                    progressTitle={progress.category_name}
                    progressSubText="Review lab test results to monitor various health aspects."
                    vitalBg="bg-background-light-yellow"
                    vitalTextColor="text-color-yellow"
                    vitalLogged="2/5"
                    handleOnclick={() => handleOnclickProgress(progress)}
                  />
                ))
              ) : (
                <div className="flex justify-center items-center p-4 text-gray-500">
                  No progress data available
                </div>
              )}
            </div>
          </div>
        }
      />
    </div>
  );
};
export default ProgressPage;
