import React, { useRef, useState } from "react";
import ReactPlayer from "react-player";

import Card from "src/components/atoms/Card/Card";

import LockedDailyRoutineOverlay from "src/assets/icons/exercise/lock-dailyroutine-overlay.svg";

interface DailyRoutineCardProps {
  videoSrc: string;
  exerciseType: string;
  exercise: any;
  reps: number;
  sets: number;
  restPostSets: string;
  rest: string;
  description: string;
  locked?: boolean;
  videoImage: string;
  difficulty?: null | string;
  done?: string;
  setSelectedExercise: (data: any) => void;
  setDifficultyModal: (data: boolean) => void;
  setDifficulty: (data: "Easy" | "Difficult") => void;
}

const DailyRoutineCard: React.FC<DailyRoutineCardProps> = (props) => {
  const {
    videoSrc,
    exerciseType,
    exercise,
    reps,
    sets,
    restPostSets,
    rest,
    description,
    locked = true,
    videoImage,
    difficulty,
    done,
    setDifficulty,
    setDifficultyModal,
    setSelectedExercise,
  } = props;

  const playerRef = useRef<ReactPlayer | null>(null);
  const containerRef = useRef<HTMLDivElement | null>(null);

  const [isExpanded, setIsExpanded] = useState(false);

  const toggleContent = () => {
    setIsExpanded(!isExpanded);
  };

  const handlePlay = () => {
    if (containerRef.current) {
      if (containerRef.current.requestFullscreen) {
        containerRef.current.requestFullscreen();
      } else if ((containerRef as any).current.webkitRequestFullscreen) {
        (containerRef as any).current.webkitRequestFullscreen();
      } else if ((containerRef as any).current.msRequestFullscreen) {
        (containerRef as any).current.msRequestFullscreen();
      }
    }

    if (playerRef.current) {
      const internalPlayer = playerRef.current.getInternalPlayer();
      if (internalPlayer && typeof internalPlayer.play === "function") {
        internalPlayer.play();
      }
    }
  };

  return (
    <Card
      className={`w-full p-[0px] rounded-xl flex flex-col flex-shrink-0 overflow-hidden relative`}
    >
      {/* YouTube Video Embed */}
      <div className="h-[100px] bg-black overflow-hidden rounded-t-lg">
        <div
          ref={containerRef}
          className="relative w-full cursor-pointer h-[100px] rounded-t-lg"
        >
          <ReactPlayer
            ref={playerRef}
            url={videoSrc}
            light={
              <img
                className="object-contain"
                src={videoImage}
                alt="Thumbnail"
              />
            }
            width="100%"
            height="100%"
            controls
            playing={false}
            onClickPreview={handlePlay}
            className="rounded-lg"
          />
          {done === "Y" && (
            <div
              className="absolute top-1 right-1 px-2 py-2 bg-white rounded-lg flex items-center justify-end gap-4 border border-background-secondary "
              onClick={() => {
                setSelectedExercise(exercise);
                if (difficulty) {
                  setDifficulty(
                    difficulty === "Difficult" ? "Difficult" : "Easy"
                  );
                }
                setDifficultyModal(true);
              }}
            >
              <div
                className={`${
                  difficulty ? "bg-ternary" : "bg-gray-300"
                } rounded-full w-4 h-4`}
              ></div>
              <span className={`text-xs text-gray-400`}>
                Difficulty {difficulty ? `: ${difficulty}` : ""}{" "}
              </span>
            </div>
          )}
        </div>
      </div>

      {/* Exercise Details */}
      <div className="h-full px-3 py-2">
        <ul className="flex flex-wrap gap-1">
          <li className="text-xs text-text-subtitle font-semibold">
            Exercise Type:{" "}
            <span className="text-text-primary">{exerciseType}</span>
          </li>
          <li className="text-xs text-text-subtitle font-semibold">
            Reps: <span className="text-text-primary">{reps}</span>
          </li>
          <li className="text-xs text-text-subtitle font-semibold">
            Sets: <span className="text-text-primary">{sets}</span>
          </li>
          <li className="text-xs text-text-subtitle font-semibold">
            Rest Post Sets:{" "}
            <span className="text-text-primary">{restPostSets}</span>
          </li>
          <li className="text-xs text-text-subtitle font-semibold">
            Rest: <span className="text-text-primary">{rest}</span>
          </li>
        </ul>
      </div>

      {/* Horizontal Line Separator */}
      <hr className="h-0.3 bg-text-subtitle" />

      {/* Description & Read More/Read Less */}
      <div className="px-4 py-2 mt-1">
        <p
          className={`text-xs text-text-subtitle py-1 ${
            !isExpanded ? "line-clamp-2" : ""
          }`}
          style={{
            display: "-webkit-box",
            WebkitBoxOrient: "vertical",
            overflow: "hidden",
            WebkitLineClamp: !isExpanded ? 2 : "unset",
          }}
        >
          {description}
        </p>
        <span
          className="text-primary cursor-pointer text-sm font-bold"
          onClick={toggleContent}
        >
          {isExpanded ? "Read Less" : "Read More"}
        </span>
      </div>

      {/* Locked Overlay*/}
      {locked && (
        <div className="absolute top-0 left-0 right-0 bottom-0 flex-col bg-black bg-opacity-65 pointer-events-auto flex justify-center items-center">
          <img src={LockedDailyRoutineOverlay} alt="Locked" />
        </div>
      )}
    </Card>
  );
};

export default DailyRoutineCard;
