import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { AppDispatch, RootState } from "src/store";
import { useDispatch } from "react-redux";
import moment from "moment";

import Card from "src/components/atoms/Card/Card";
import Button from "src/components/atoms/Button/Button";
import ExpandableDivs from "src/components/atoms/Layout/ExpandingLayout";
import ProgressBackIcon from "src/components/atoms/ProgressBackIcon/ProgressBackIcon";
import ProgressResultDetailsCard from "src/components/molecules/progress/ProgressResultDetailsCard";
import BottomSheetSelect from "src/components/atoms/BottomSheetSelect";
import TextInputAnimated from "src/components/atoms/TextInputAnimated";
import { setSelectedDetailsMasterId, setUpdatedValue, updatePatientReadingsRequest } from "src/store/slices/progressSlice";
import { RightArrowIcon } from "src/components/atoms/Icons/RightArrowIcon";
import { categoryReading } from "src/types/progress";

const ProgressResultDetailsPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  const [showBottomSheet, setshowBottomSheet] = useState<boolean>(false);
  const [categoryReading, setCategoryReading] = useState<categoryReading | null>(null);

  const { selectedProgress } = useSelector((state: RootState) => state.progress);
  const theme = useSelector((state: RootState) => state.theme);

  const handleClickSelectedProgress = (selectedMasterId: string) => {
    dispatch(setSelectedDetailsMasterId({ selectedProgressMasterId: selectedMasterId }));
    navigate("recorded-data");
  };

  const handleClickOfProgressCard = (categoryReading: categoryReading) => {
    setCategoryReading(categoryReading);
    setshowBottomSheet(true);
  };

  const handleChangeRedingValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (categoryReading) {
      setCategoryReading({
        ...categoryReading,
        reading_value: e.target.value,
      });
    }
  };

  const handleClickOfUpdatePatientReading = async () => {
    const payload: any = {
      reading_datetime: moment().format("YYYY-MM-DD HH:mm:ss"),
      reading_id: categoryReading?.readings_master_id,
      reading_value: categoryReading?.reading_value,
      weight_unit: categoryReading?.unit,
    };
    await new Promise((resolve, reject) => {
      dispatch(
        updatePatientReadingsRequest({
          payload,
          resolve,
          reject,
        })
      );
    })
      .then((result: any) => {
        if (result.code == 1) {
          setshowBottomSheet(false)
          dispatch(setUpdatedValue({
            master_id: categoryReading?.readings_master_id,
            value: categoryReading?.reading_value
          }));
          // console.log("Result during dispatch:", result);
        }
      })
      .finally(() => {
        console.log("Finally block executed");
        setshowBottomSheet(false)
        // hideLoader();
      });
  };

  return (
    <div className=" bg-background-light-blue h-full">
      <ExpandableDivs
        showExpandBtn={false}
        component1={
          <div>
            <ProgressBackIcon onClick={() => navigate(-1)} />
          </div>
        }
        component2={
          <div>
            <section className="p-6 flex flex-col justify-center items-center gap-4">
              <Card className="flex !rounded-[31px]">
                <img src={selectedProgress?.icon_url} alt={selectedProgress?.category_name} className="w-[92px] object-cover" />
              </Card>
              <div className="flex flex-col gap-[6px] justify-center items-center">
                <p className="font-semibold text-base text-text-primary">{selectedProgress?.category_name}</p>
                <p className="text-sm text-text-secodary flex justify-center text-center">
                  {"Track key heart and blood circulation metrics to ensure a healthy cardiovascular system."}
                </p>
              </div>
            </section>

            <section className="p-6 flex flex-col gap-4">
              {selectedProgress?.data && selectedProgress.data.length > 0 ? (
                selectedProgress.data.map((categoryReading: any, index: any) => (
                  <ProgressResultDetailsCard
                    key={index}
                    progressTitle={categoryReading.reading_name}
                    progressValue={categoryReading.reading_value ? parseFloat(categoryReading.reading_value).toString() : "00"}
                    progressUnit={categoryReading.unit}
                    vitalBg={categoryReading.color_code}
                    vitalTextColor="text-color-extra-dark-green"
                    idealRange="60-100 bpm"
                    loggedDate={
                      categoryReading.reading_datetime
                        ? moment(categoryReading.reading_datetime, "YYYY-MM-DD HH:mm:ss").format("DD MMM YYYY")
                        : ""
                    }
                    status="Within ideal range"
                    handleOnclick={() => handleClickSelectedProgress(categoryReading.readings_master_id)}
                    startRange={60}
                    endRange={100}
                    activeRange={80}
                    onclickOfCard={() => handleClickOfProgressCard(categoryReading)}
                  />
                ))
              ) : (
                <div className="flex justify-center items-center p-4 text-text-ternary">
                  No progress data available
                </div>
              )}
            </section>

            <section className="p-4">
              <Card className="p-4 !rounded-3xl flex flex-col gap-4">
                <div className="flex justify-between ">
                  <div className="font-semibold text-base text-text-primary">Cardiac Output</div>
                  <div className="bg-color-light-red text-color-dark-red rounded-full text-sm py-1 px-3">Not Logged</div>
                </div>
                <div>
                  <Button label="Log Your Vital" type="button" variant="ternary" className="w-full" onClick={() => { }}>
                    <RightArrowIcon color={theme.colors["--color-dark-blue"]} />
                  </Button>
                </div>
              </Card>
            </section>

            {showBottomSheet && (
              <BottomSheetSelect open={showBottomSheet} close={() => setshowBottomSheet(false)}>
                <section className="p-4">
                  <h1 className="text-xl font-bold text-text-secodary">{categoryReading?.reading_name}</h1>
                </section>
                <hr className="h-[2px] bg-background" />

                <section className="p-4 flex flex-col gap-3">
                  <div className="flex gap-2">
                    <TextInputAnimated
                      id="Reding Value"
                      label="Reding Value"
                      value={categoryReading?.reading_value ? parseFloat(categoryReading.reading_value).toString() : "00"}
                      required
                      readonly={false}
                      onChange={(e) => handleChangeRedingValue(e)}
                    />
                    <div className="flex items-center justify-center bg-color-extra-light-gray text-text-secodary p-2 rounded-md">
                      {categoryReading?.unit || "Unit"}
                    </div>
                  </div>
                  <Button variant="primary" label="Update" name="Update" className="w-full" onClick={handleClickOfUpdatePatientReading} />
                </section>
              </BottomSheetSelect>
            )}
          </div>
        }
      />
    </div>
  );
};

export default ProgressResultDetailsPage;
